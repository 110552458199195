.card-text {
  font-size: 18px;
  color: white;
  line-height: 130%;
  padding: 0px;
}

.card-text-toggle {
  color: #b0b0b0;
  font-size: 15px;
  font-family: "Helvetica";
  /* font-style: italic; */
  background-image: "linear-gradient(to bottom, #ed2024, #ed2024);";
}

/* #ed2024 the brighter green */
.card-text-highlight {
  background-image: linear-gradient(to bottom, #ed2024, #ed2024);
  /* background-size: 75% 50%; */
  /* height: 50%; */
  color: black;
}

.card-text-highlight-quote {
  background-image: linear-gradient(to bottom, #ed2024, #ed2024);
  /* background-size: 75% 50%; */
  /* height: 50%; */
  color: black;
  font-size: 18px;
  font-family: "Helvetica";
  font-style: italic;
}

.card-text-link {
  /* background-image: linear-gradient(to bottom, #ed2024, #ed2024); */
  color: white;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: #ed2024;
  padding-bottom: 3px;
}

.card-text-link-quote {
  color: #ed2024;
  font-size: 18px;
  font-family: "Helvetica";
  font-style: italic;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: #ed2024;
}

.card-toggle {
  color: #ed2024;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}

.arrow {
  border: solid #ed2024;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 2.5px;
  margin: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
