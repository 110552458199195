/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .scrollable-video__video {
  position: fixed;
  top: 0;
  width: 100vw;
  height: auto;
} */

.slider {
  position: relative;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image {
  width: auto;
  height: 40vh;
  /* height: auto; */
  border-radius: 0px;
  object-fit: contain;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 0px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 0px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  /* transform: scale(1.08); */
}

.full-screen-content {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background-color: black;
  pointer-events: none;
  background-color: black;
  pointer-events: none;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  -webkit-transition: opacity 1.5s linear;
  -moz-transition: opacity 1.5s linear;
  -o-transition: opacity 1.5s linear;
  transition: opacity 1.5s linear;
}

.visible {
  opacity: 1;
}

.visible-eighty {
  opacity: 0.7;
  z-index: 200;
}

.hidden {
  opacity: 0;
}
