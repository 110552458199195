:root {
  --progress-tracker-width: 0vw;
}

.progress-bar__container {
  z-index: 1000;
  position: fixed;
  top: 0;
  overflow-x: hidden;
  /* height: 3px; */
  width: 100vw;
  background-color: gray;
}

.progress-bar__tracker {
  height: 2px;
  background: #ed2024;
  width: var(--progress-tracker-width);
  animation: width 0.2s ease-in;
}
