.nav-bar {
  top: 2px;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 1000;
  opacity: 1;
  background-color: black;
  width: 100vw;
  height: 50px;
  overflow-x: scroll;
}

.nav-bar::-webkit-scrollbar {
  height: 0px !important;
  width: 0px !important;
}

.nav-button {
  cursor: pointer;
  font-size: 0.8vw;
  color: white;
  border: none;
  padding: 0.1vw 0vw;
  margin: 0vw 0.7vw;
  background-color: black;
  text-transform: uppercase;
  font-weight: bold;

  /* line-height: 130%;
  float: left; */
}

.nav-button:hover {
  color: #ed2024;
  /* border: 1px solid #dc5042; */
}

.current {
  padding-bottom: 5px;
  border-bottom: 2px solid #ed2024;
}


.nav-logo {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 50px;
  padding: 0vw 0vw 0vw 0.1vw;
  cursor: pointer;
}

.nav-title-container {
  position: absolute;
  left: 50%;
  color: white;
}

.nav-title {
  color: #ed2024;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.5vh;
}

.nav-title-mobile {
  color: #ed2024;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 4vw;
}

