.FA-button {
  cursor: pointer;
  font-size: 16px;
  color: white;
  border: 1px solid white;
  padding: 10px 24px;
  background-color: black;
  line-height: 130%;
  float: left;
}

.FA-button:hover {
  /* color: #dc5042; */
  border: 1px solid #ed2024;
}
