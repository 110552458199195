.toggle {
  position: absolute;
  right: 120px;
}

.checked-icon, .unchecked-icon {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: bold;
  font-size: 0.7rem;
}

.checked-icon {
  padding-left: 4px;
}

.unchecked-icon {
  padding-left: 1px;
}

